<div class="page-banner" style="background-image: url(assets/img/contact-image.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 translate>human_policy_title</h2>
                    <ul>
                        <li><a routerLink="/" translate>home</a></li>
                        <li translate>human_policy_title</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-text-area ptb-100">
    <div class="container">
        <div class="row align-items-center text-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-safe-text">
                    <h2 translate>human_policy_title</h2>
                    <p translate>human_policy_1</p> 
                    <p translate>human_policy_2</p> 
                    <p translate>human_policy_3</p> 
                    <p translate>human_policy_4</p> 
                    <p translate>human_policy_5</p> 
                    <p translate>human_policy_6</p> 
                    <p translate>human_policy_7</p> 
                    <p translate>human_policy_8</p> 
                    <p translate>human_policy_9</p> 
                    <p translate>human_policy_10</p> 
                    <p translate>human_policy_11</p> 
                    <p translate>human_policy_12</p> 
                    <p translate>human_policy_13</p> 
                    <p translate>human_policy_14</p> 
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container mb-5">
    <div class="counter-area">
        <div class="counter-contant">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class="bx bx-world"></i>
                        <h3><span class="odometer" data-count="49">00</span></h3>
                        <p translate="sayac_title1"></p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class='bx bxs-home'></i>
                        <h3><span class="odometer" data-count="2300">00</span></h3>
                        <p translate="sayac_title2"></p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class='bx bxs-truck'></i>
                        <h3><span class="odometer" data-count="3200">00</span></h3>
                        <p translate="sayac_title3"></p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class='bx bxs-group'></i>
                        <h3><span class="odometer" data-count="5000">00</span></h3>
                        <p translate="sayac_title4"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>