import { Component, OnInit } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  form: FormGroup;
  status: object;
  returnText: String;
  returnStatus: String;
  returnError: String;
  name = new FormControl('');

  constructor(public fb: FormBuilder,private http: HttpClient,private translate: TranslateConfigService) {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      email: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      malCinsi: ['', Validators.required],
      m3: ['', Validators.required],
      kg: ['', Validators.required],
      yukleme: ['', Validators.required],
      bosaltma: ['', Validators.required],
      aciklama: ['', Validators.required]
    })
  }

  ngOnInit(): void {
  }

  onSubmit() {
    console.log(this.form.value);
    this.http.post('https://bybitter.net/altuntur/eu-teklif.php', this.form.value).subscribe(
    (response) => this.updateMessage(response["message"],response["status"],response["error"]),
    (error) => console.log(error))
  }

  get f(){
    return this.form.controls;
  }

  updateMessage(text:string,status:string,error:string): void {
    this.returnText = text;
    this.returnStatus = status;
    this.returnError = error;
  }

} 
 