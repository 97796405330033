<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget" style="margin: -33px 0 0 0 !important;">
                    <div class="logo">
                        <img src="assets/img/logo3.png" alt="logo">
                    </div>
                    <p translate>footer_text</p>
                    <ul class="footer-socials">
                        <li><a href="https://facebook.com/altuntureuropa" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://instagram.com/altuntur_europa_gmbh" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-80">
                    <h3 translate>corporate</h3>
            
                    <ul class="footer-text">
                        <li><a routerLink="/about" translate>about</a></li>
                        <li><a routerLink="/mission" translate>mission</a></li>
                        <li><a routerLink="/vision" translate>vision</a></li>
                        <li><a routerLink="/documents" translate>documents</a></li>
                        <li><a routerLink="/human-policy" translate>human_resources</a></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-50">
                    <h3 translate>footer_destek</h3>
            
                    <ul class="footer-text">
                        <li><a routerLink="/" translate>footer_kvkk</a></li>
                        <li><a routerLink="/privacy-policy" translate>privacy_policy</a></li>
                        <li><a routerLink="/contact" translate>contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <h3 translate>footer_iletisim</h3>

                    <ul class="info-list">
                        <li><i class='bx bxs-location-plus'></i> Heuweg 90, 67065 Ludwigshafen am Rhein, Almanya</li>
                        <li><i class='bx bxs-envelope'></i> <a href="mailto:info@altuntur.com">info@altuntureuropa.com</a></li>
                        <li><i class='bx bxs-phone'></i> <a href="tel:4915168755555">+49 1516 8755555</a></li>
                        <li><i class='bx bxs-phone'></i> <a href="tel:491625800333">+49 162 5800333</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>


<div class="footer-bottom">
    <div class="container">
        <p translate>footer_copy</p>
    </div>
</div>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
</div>