<!-- Hero Area -->
<div class="hero-slider-two owl-carousel owl-theme">
    <div class="hero-slider-two-item item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="banner-content">
                                <span>Since 1992</span>
                                <h1>Digital Shipping Solution</h1>
                                <p>At vero eos et accusamus et iusto odio vero ducimus qui blanditiis iusto voluptatum</p>
                                <a routerLink="/contact" class="default-btn-one mr-3">Contact Us</a>
                                <a routerLink="/" class="default-btn-two">Get A Quote</a>
                            </div>
                        </div>
 
                        <div class="col-lg-6 col-md-12">
                            <div class="order-form">
                                <h2>Track Your Order</h2>
                                
                                <form>
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Tracking ID No.">
                                    </div>

                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Enter Email">
                                    </div>

                                    <div class="text-center">
                                        <button type="submit" class="default-btn-one">Track Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hero-slider-two-item item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="banner-content">
                                <span>Since 1992</span>
                                <h1>Digital Shipping Solution</h1>
                                <p>At vero eos et accusamus et iusto odio vero ducimus qui blanditiis iusto voluptatum</p>
                                <a routerLink="/contact" class="default-btn-one mr-3">Contact Us</a>
                                <a routerLink="/" class="default-btn-two">Get A Quote</a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="order-form">
                                <h2>Track Your Order</h2>
                                
                                <form>
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Tracking ID No.">
                                    </div>

                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Enter Email">
                                    </div>

                                    <div class="text-center">
                                        <button type="submit" class="default-btn-one">Track Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hero-slider-two-item item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="banner-content">
                                <span>Since 1992</span>
                                <h1>Digital Shipping Solution</h1>
                                <p>At vero eos et accusamus et iusto odio vero ducimus qui blanditiis iusto voluptatum</p>
                                <a routerLink="/contact" class="default-btn-one mr-3">Contact Us</a>
                                <a routerLink="/" class="default-btn-two">Get A Quote</a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="order-form">
                                <h2>Track Your Order</h2>
                                
                                <form>
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Tracking ID No.">
                                    </div>

                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Enter Email">
                                    </div>

                                    <div class="text-center">
                                        <button type="submit" class="default-btn-one">Track Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Hero Area -->

<!-- Freight Card Area -->
<div class="freight-area pb-70">
    <div class="container">
        <div class="freight-inner">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="service-card-two">
                        <img class="freight-image" src="assets/img/freight/freight1.jpg" alt="image">
                        <div class="service-caption">
                            <h3>Road Freight</h3>
                            <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the.</p>
                            <a routerLink="/services" class="default-btn-two">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="service-card-two">
                        <img class="freight-image" src="assets/img/freight/freight2.jpg" alt="image">
                        <div class="service-caption">
                            <h3>Ocean Freight</h3>
                            <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the.</p>
                            <a routerLink="/services" class="default-btn-two">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="service-card-two">
                        <img class="freight-image" src="assets/img/freight/freight3.jpg" alt="image">
                        <div class="service-caption">
                            <h3>Air Freight</h3>
                            <p>But I must explain to you how all this mistaken idea of denouncing  I will give you a complete account of the.</p>
                            <a routerLink="/services" class="default-btn-two">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Freight Card Area -->

<!-- About Area -->
<div class="about-area-two pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-two-img">
                    <img src="assets/img/about/about2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-contant-others">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>Safe and Faster Logistic Service Near You</h2>
                    </div>

                    <div class="about-two-text">
                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati.</p>
                        <ul>
                            <li><i class='bx bx-check'></i> We provide the best logistic service worldwide</li>
                            <li><i class='bx bx-check'></i> All payment methods we accept for ordering our service.</li>
                            <li><i class='bx bx-check'></i> We provide proper safety and security.</li>
                        </ul>
                        <a routerLink="/contact" class="default-btn-one mr-3">Contact Us</a>
                        <div class="watch-video">
                            <div class="video-btn">
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="popup-youtube"><i class="bx bx-play whiteText"></i> Watch Video</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End About Area -->

<!-- Counter Area -->
<div class="container">
    <div class="counter-area">
        <div class="counter-contant">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class="bx bx-world"></i>
                        <h3><span class="odometer" data-count="120">00</span></h3>
                        <p>Countries Served</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class='bx bxs-ship'></i>
                        <h3><span class="odometer" data-count="2300">00</span></h3>
                        <p>Ports Served</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class='bx bxs-truck'></i>
                        <h3><span class="odometer" data-count="3200">00</span></h3>
                        <p>Road Served</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class='bx bxs-group'></i>
                        <h3><span class="odometer" data-count="5000">00</span></h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Counter Area -->

<!-- Services Area -->
<div class="services-area services-two pb-100">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>We Provide Best Logistic<br> Services</h2>
        </div>

        <div class="services-slider owl-carousel owl-theme"> 
            <div class="service-card">
                <i class='bx bxs-ship'></i>
                <h3>Ocean Cargo</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                <a routerLink="/services" class="default-btn-two">Read More</a>
            </div>

            <div class="service-card">
                <i class='bx bx-store'></i>
                <h3>Cargo Storage</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                <a routerLink="/services" class="default-btn-two">Read More</a>
            </div>
            
            <div class="service-card">
                <i class='bx bxs-truck'></i>
                <h3>Courier Delivery</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                <a routerLink="/services" class="default-btn-two">Read More</a>
            </div>
            
            <div class="service-card">
                <i class='bx bx-transfer'></i>
                <h3>B2B Exchange</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                <a routerLink="/services" class="default-btn-two">Read More</a>
            </div>

            <div class="service-card">
                <i class='bx bxs-ship'></i>
                <h3>Ocean Cargo</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                <a routerLink="/services" class="default-btn-two">Read More</a>
            </div>

            <div class="service-card">
                <i class='bx bx-store'></i>
                <h3>Cargo Storage</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                <a routerLink="/services" class="default-btn-two">Read More</a>
            </div>
            
            <div class="service-card">
                <i class='bx bxs-truck'></i>
                <h3>Courier Delivery</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                <a routerLink="/services" class="default-btn-two">Read More</a>
            </div>
            
            <div class="service-card">
                <i class='bx bx-transfer'></i>
                <h3>B2B Exchange</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                <a routerLink="/services" class="default-btn-two">Read More</a>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Choose Us Area -->
<div class="choose-us-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-content">
                    <div class="choose-us-text">
                        <div class="section-title">
                            <span>Why Choose Us</span>
                            <h2>Modern, Safe And Trusted Logistic Company</h2>
                        </div>
                    </div>
                    <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Worldwide service</li>
                        <li><i class='bx bx-check'></i> 24/7 support</li>
                        <li><i class='bx bx-check'></i> Tracking service</li>
                        <li><i class='bx bx-check'></i> First and reliable</li>
                    </ul>
                    <a routerLink="/contact" class="default-btn-one">Contact Us</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-us-image">
                    <img src="assets/img/choose/choose1.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Choose Us Area -->

<!-- Personal Data Area -->
<div class="personal-data-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="safely-image">
                    <img src="assets/img/safely/safely1.jpg" alt="image">

                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <h3>Take Your Goods Anywhere Safely And In Time</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam deserunt mollit anim id est laborum.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="personal-data-form">
                    <h2>Personal Data</h2>

                    <form>
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Phone No.">
                                </div>
                            </div>
                        </div>

                        <h2>Shipment Data</h2>
                        
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <select class="form-control" id="FreightType">
                                    <option>Freight Type</option>
                                    <option>Height</option>
                                    <option>Weight</option>
                                    <option>Length</option>
                                    <option>Width</option>
                                    <option>Distance</option>
                                </select>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Height">
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Weight">
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Length">
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Width">
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Distance">
                                </div>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary">Submit</button>
                        <p><span>*</span>Get a brochure / <a href="#">10% Discount</a>  on first order</p>
                    </form>
                </div>
            </div>
        </div>

        <div class="shape-three"><img src="assets/img/shape/shape6.png" alt="Shape"></div>
    </div>
</div>
<!-- End Personal Data Area -->

<!-- Feedback Slider -->
<div class="feedback-area ptb-100">
    <div class="container">
        <div class="shape-four"><img src="assets/img/shape/shape3.png" alt="Image"></div>

        <div class="section-title">
            <span>Clients Review</span>
            <h2>Our Clients Around The World Makes Us Special</h2>
        </div>

        <div class="feedback-slider owl-carousel owl-theme">
            <div class="feedback-slider-item">
                <div class="feedback-icon-one">
                    <i class='bx bxs-quote-left'></i>
                </div>
                <div class="feedback-img">
                    <img src="assets/img/feedback/feedback1.jpg" alt="image">
                    <h3>Jhon Smith</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div> 
                <div class="feedback-text">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
                <div class="feedback-icon-two">
                    <i class='bx bxs-quote-right' ></i>
                </div>
            </div>

            <div class="feedback-slider-item">
                <div class="feedback-icon-one">
                    <i class='bx bxs-quote-left'></i>
                </div>
                <div class="feedback-img">
                    <img src="assets/img/feedback/feedback2.jpg" alt="image">
                    <h3>Jhon Smith</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div> 
                <div class="feedback-text">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
                <div class="feedback-icon-two">
                    <i class='bx bxs-quote-right' ></i>
                </div>
            </div>

            <div class="feedback-slider-item">
                <div class="feedback-icon-one">
                    <i class='bx bxs-quote-left'></i>
                </div>
                <div class="feedback-img">
                    <img src="assets/img/feedback/feedback3.jpg" alt="image">
                    <h3>Jhon Smith</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div> 
                <div class="feedback-text">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
                <div class="feedback-icon-two">
                    <i class='bx bxs-quote-right' ></i>
                </div>
            </div>

            <div class="feedback-slider-item">
                <div class="feedback-icon-one">
                    <i class='bx bxs-quote-left'></i>
                </div>
                <div class="feedback-img">
                    <img src="assets/img/feedback/feedback4.jpg" alt="image">
                    <h3>Jhon Smith</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div> 
                <div class="feedback-text">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
                <div class="feedback-icon-two">
                    <i class='bx bxs-quote-right' ></i>
                </div>
            </div>

            <div class="feedback-slider-item">
                <div class="feedback-icon-one">
                    <i class='bx bxs-quote-left'></i>
                </div>
                <div class="feedback-img">
                    <img src="assets/img/feedback/feedback5.jpg" alt="image">
                    <h3>Jhon Smith</h3>
                    <span>CEO of LTD company</span>
                </div>
                <div class="rating">
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                    <i class='bx bxs-star'></i>
                </div> 
                <div class="feedback-text">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                </div>
                <div class="feedback-icon-two">
                    <i class='bx bxs-quote-right' ></i>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Feedback Slider -->

<!-- Blog Area -->
<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title ">
            <span>Our Blog</span>
            <h2>Read Our Latest News and Blogs on Logistic</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Delivery</span>
                        <div class="date">22 August 2020</div>
                        <h3><a routerLink="/blog-details">We ensure you best the quality services</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Transport</span>
                        <div class="date">23 August, 2020</div>
                        <h3><a routerLink="/blog-details">Air cargo may become short-term solution</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a class="blog-image" routerLink="/blog-details"><img src="assets/img/blog/blog3.jpg" alt="image"></a>
                    <div class="blog-text">
                        <span class="meta-tag">Delivery</span>
                        <div class="date">24 August, 2020</div>
                        <h3><a routerLink="/blog-details">We introduce new boat and flight service</a></h3>
                        <p class="mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor</p>
                        <a routerLink="/blog-details" class="default-btn-two">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Area -->

<!-- Newsletter Area -->
<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Newsletter Area -->