import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-humanpolicy',
  templateUrl: './humanpolicy.component.html',
  styleUrls: ['./humanpolicy.component.scss']
})
export class HumanpolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
