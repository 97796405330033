import { NgModule, Pipe, PipeTransform  } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BranchesComponent } from "./branches.component";
import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@NgModule({
  imports: [CommonModule,FormsModule,Ng2SearchPipeModule],
  declarations: [BranchesComponent]
})

export class BranchesModule {}