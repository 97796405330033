import { Component, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
 
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  form: FormGroup;
  status: object;
  returnText: String;
  returnStatus: String;
  returnError: String;
  name = new FormControl('');

  constructor(public fb: FormBuilder,private http: HttpClient,private translate: TranslateConfigService) {
    this.form = this.fb.group({
      name: new FormControl('', Validators.required),
      email: ['', Validators.required],
      msgSubject: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      message: ['', Validators.required]
    })
  }


  ngOnInit(): void {
  }
  
  onSubmit() {
    //console.log(this.form.value);
    this.http.post('https://bybitter.net/altuntur/eu-contact.php', this.form.value).subscribe(
    (response) => this.updateMessage(response["message"],response["status"],response["error"]),
    (error) => console.log(error))


  }

  get f(){
    return this.form.controls;
  }

  updateMessage(text:string,status:string,error:string): void {
    this.returnText = text;
    this.returnStatus = status;
    this.returnError = error;
  }
}
