import { Component, OnInit } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private translate: TranslateConfigService) { }

  ngOnInit(): void {
  }

  /* Change default language */
  changeDefaultLanguage(langType: string){
    this.translate.changeLanguage(langType);
  }

  

}
