<div class="page-banner" style="background-image: url(assets/img/services-image.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 translate="service_custom_1"></h2>
                    <ul>
                        <li><a routerLink="/services" translate="hizmetler"></a></li>
                        <li translate="service_custom_1"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text"> 
                    <div class="service-image">
                        <img src="assets/img/services/services1.jpg" alt="image">
                    </div>
                    <h2 translate="service_custom_1"></h2>
                    <p translate="service_road_desc1"></p>
                    <p translate="service_road_desc2"></p>
                    
                    <h4 class="mt-5" translate="service_road_title1"></h4>
                    <p translate="service_road_title_desc1"></p>
                
                    <h4 class="mt-5" translate="service_road_title2"></h4>
                    <p translate="service_road_title_desc2"></p>
                
                    <h4 class="mt-5" translate="service_road_title3"></h4>
                    <p translate="service_road_title_desc3"></p>
                </div> 
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title" translate="service_custom_diger"></h3>

                    <ul>
                        <li><a href="/services-road"><i class='bx bxs-truck'></i> <span translate="service_custom_1"></span></a></li>
                        <li><a href="/services-ocean"><i class='bx bxs-ship'></i> <span translate="service_custom_2"></span></a></li>
                        <li><a href="/services-air"><i class='bx bxs-plane-take-off'></i> <span translate="service_custom_3"></span></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3 translate="bulten_title"></h3>
                        <p translate="bulten_desc"></p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="{{ 'bulten_input' | translate }}" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary" translate="bulten_btn"></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>