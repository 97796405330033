<header class="header-area">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <ul class="left-info">
                        <li><a href="mailto:info@altuntureuropa.com"><i class='bx bxs-envelope'></i> info@altuntureuropa.com</a></li>
                        <li><a href="tel:4915168755555"><i class='bx bxs-phone-call'></i> +49 1516 8755555</a></li>
                        <li><a href="tel:491625800333"><i class='bx bxs-phone-call'></i> +49 162 5800333</a></li>
                    </ul>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <ul class="right-info">
                        <select id="mySelect" data-show-content="true" class="form-control" style="width: 140px;padding: 0 10px;margin-right: 6px;display: inline-block;" (change)="changeDefaultLanguage($event.target.value)">
                            <option value="en">ENGLISH</option>
                            <option value="tr">TÜRKÇE</option> 
                            <option value="ar">عربي</option>
                            <option value="de">DEUTSCH</option>
                        </select>
                        <li><a href="https://facebook.com/altuntureuropa" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://instagram.com/altuntur_europa_gmbh" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
    <div class="navbar-area">
        <div class="floza-responsive-nav">
            <div class="container">
                <div class="floza-responsive-menu">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo3.png" alt="logo" class="logo"> </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="floza-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo3.png" alt="logo" class="logo"></a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav ms-auto">
                            <li class="nav-item">
                                <a routerLink="/home" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" translate>home</a>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" translate>corporate <i class='bx bx-chevron-down'></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" translate>about</a></li>
                                    <li class="nav-item"><a routerLink="/vision" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" translate>vision</a></li>
                                    <li class="nav-item"><a routerLink="/mission" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" translate>mission</a></li>
                                    <li class="nav-item"><a routerLink="/human-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" translate>human_resources</a></li>
                                    <li class="nav-item"><a routerLink="/documents" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" translate>documents</a></li>
                                    <li class="nav-item"><a routerLink="/billing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" translate>billing</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="/services" class="nav-link" translate>hizmetler <i class='bx bx-chevron-down'></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/services-road" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" translate>hizmet_kara</a></li>
                                    <li class="nav-item"><a routerLink="/services-oceon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" translate>hizmet_deniz</a></li>
                                    <li class="nav-item"><a routerLink="/services-air" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" translate>hizmet_hava</a></li>
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/branches" class="nav-link" translate>subeler</a>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/agencies" class="nav-link" translate>agencies</a>
                            </li>

                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" translate>contact</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>