<div class="page-banner" style="background-image: url(assets/img/contact-image.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Şubelerimiz</h2>
                    <ul>
                        <li><a routerLink="/">Anasayfa</a></li>
                        <li translate>subeler</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mt-3 pb-70">
    <div class="container">
        <div class="row">
            <div class="page-title text-center mb-3"><h2>Şube Adres Bilgileri</h2></div>

            <div class="col-md-12">
                <div class="form">
                    <input id="search" type="search" placeholder="Search..." [(ngModel)]="filterTerm"/>
                </div>
            </div>
            
            <div *ngFor="let item of dataList | filter:filterTerm">

                <div class="col-lg-12 col-md-12">
                    <div class="branches-info">
                        <ng-template [ngIf]="item.title != ''"><h4><i class="bx bx-chevron-right title"></i> {{item.title}} / {{item.country}} <span style="float: right;">{{item.code}}</span></h4> </ng-template>
                        <div class="box">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <ng-template [ngIf]="item.title != ''"><p><i class="bx bx-building"></i> {{item.company}} </p></ng-template>
                                    <ng-template [ngIf]="item.authorized != ''"><p><i class="bx bx-user"></i> {{item.authorized}} </p></ng-template>
                                    <ng-template [ngIf]="item.phone1 != ''"><p><i class="bx bx-phone"></i> {{item.phone1}} </p></ng-template>
                                    <ng-template [ngIf]="item.mail != ''"><p><i class="bx bx-mail-send"></i> {{item.mail}} </p></ng-template>
                                    <ng-template [ngIf]="item.address1 != ''"><p><i class="bx bx-location-plus"></i> {{item.address1}} </p></ng-template>
                                    <ng-template [ngIf]="item.address2 != ''"><p><i class="bx bx-location-plus"></i> {{item.address2}} </p></ng-template>
                                    <ng-template [ngIf]="item.city != ''"><p><i class="bx bx-world"></i> {{item.city}} / {{item.country}} </p></ng-template>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <ng-template [ngIf]="item.location != ''"><iframe [src]="item.location" width="550" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe></ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>



<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Haber Bültenine Abone Ol</h3>
                        <p>Güncel kampanyalardan haberdar olmak için abone olabilirsiniz.</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Email Adresiniz" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Abone Ol</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>