<div class="page-banner" style="background-image: url(assets/img/contact-image.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 translate>about</h2>
                    <ul>
                        <li><a routerLink="/" translate>home</a></li>
                        <li translate>about</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="contact-form-area pb-100 pt-5">
    <div class="container">
        <div class="section-title">
            <span translate>contact_page_form1</span>
            <h2 translate>contact_page_form1</h2>
        </div>

        <div class="contact-form">

            <div *ngIf="returnText && returnStatus == 'true'" class="alert alert-success text-center">{{ returnText }}</div>
            <div *ngIf="returnText && returnStatus == 'false'" class="alert alert-danger text-center">{{ returnText }} {{ returnError }}</div>

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-md-12 col-md-12">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="name" placeholder="{{ 'label_page_form_ad' | translate }}" required="required">
                            <div *ngIf="f.name.touched && f.name.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.name.errors && f.name.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="email" class="form-control" formControlName="email" required placeholder="{{ 'contact_page_form_email' | translate }}">
                            <div *ngIf="f.email.touched && f.email.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.email.errors && f.email.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="phoneNumber" required placeholder="{{ 'label_page_form_tel' | translate }}">
                            <div *ngIf="f.phoneNumber.touched && f.phoneNumber.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.phoneNumber.errors && f.phoneNumber.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="ulke" required placeholder="{{ 'label_page_form_ulke' | translate }}">
                            <div *ngIf="f.ulke.touched && f.ulke.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.ulke.errors && f.ulke.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="il" required placeholder="{{ 'label_page_form_il' | translate }}">
                            <div *ngIf="f.il.touched && f.il.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.il.errors && f.il.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>

                    
                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="adres" required placeholder="{{ 'label_page_form_address' | translate }}">
                            <div *ngIf="f.adres.touched && f.adres.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.adres.errors && f.adres.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="postakod" required placeholder="{{ 'label_page_form_posta' | translate }}">
                            <div *ngIf="f.postakod.touched && f.postakod.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.postakod.errors && f.postakod.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn-one" translate>contact_page_form_btn</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>