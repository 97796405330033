<link rel="stylesheet" href="https://cdn.rawgit.com/igorlino/fancybox-plus/1.3.6/css/jquery.fancybox-plus.css" type="text/css" media="screen" />
<script type="text/javascript" src="https://cdn.rawgit.com/igorlino/fancybox-plus/1.3.6/src/jquery.fancybox-plus.js"></script>

<div class="page-banner" style="background-image: url(assets/img/contact-image.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 translate>documents_title</h2>
                    <ul>
                        <li><a routerLink="/" translate>home</a></li>
                        <li translate>documents_title</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-text-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-safe-text">
                    <h1 translate class="text-center mb-5">documents_title</h1>
                    
                    <div class="row">

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card-two">
                                <a href="assets/img/belge/1.jpg" target="_blank"><img src="assets/img/belge/1.jpg" alt="image" class="freight-image"></a>
                                <div class="service-caption">
                                    <h3>İhracat Rekortmeni</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card-two">
                                <a href="assets/img/belge/1.jpg" target="_blank"><img src="assets/img/belge/2.jpg" alt="image" class="freight-image"></a>
                                <div class="service-caption">
                                    <h3>Takdir Beratı</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card-two">
                                <a href="assets/img/belge/1.jpg" target="_blank"><img src="assets/img/belge/3.jpg" alt="image" class="freight-image"></a>
                                <div class="service-caption">
                                    <h3>İhracat Rekortmeni</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card-two">
                                <a href="assets/img/belge/1.jpg" target="_blank"><img src="assets/img/belge/4.jpg" alt="image" class="freight-image"></a>
                                <div class="service-caption">
                                    <h3>Takdir Beratı</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card-two">
                                <a href="assets/img/belge/1.jpg" target="_blank"><img src="assets/img/belge/5.jpg" alt="image" class="freight-image"></a>
                                <div class="service-caption">
                                    <h3>İhracat Rekortmeni</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card-two">
                                <a href="assets/img/belge/1.jpg" target="_blank"><img src="assets/img/belge/6.jpg" alt="image" class="freight-image"></a>
                                <div class="service-caption">
                                    <h3>İhracat Rekortmeni</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card-two">
                                <a href="assets/img/belge/1.jpg" target="_blank"><img src="assets/img/belge/7.jpg" alt="image" class="freight-image"></a>
                                <div class="service-caption">
                                    <h3>İhracat Rekortmeni</h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="service-card-two">
                                <a href="assets/img/belge/1.jpg" target="_blank"><img src="assets/img/belge/8.jpg" alt="image" class="freight-image"></a>
                                <div class="service-caption">
                                    <h3>Marka Tescil Belgesi</h3>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

