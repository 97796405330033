import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-road',
  templateUrl: './services-road.component.html',
  styleUrls: ['./services-road.component.scss']
})
export class ServicesRoadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
