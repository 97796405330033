<div class="page-banner" style="background-image: url(assets/img/contact-image.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 translate>contact</h2>
                    <ul>
                        <li><a routerLink="/" translate>home</a></li>
                        <li translate>contact</li>
                    </ul>
                </div>
            </div>
        </div> 
    </div>
</div>


<div class="pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info">
                    <i class='bx bxs-phone'></i>
                    <h4 translate>contact_page_numara</h4>
                    <p> <a href="tel:4915168755555">+49 1516 8755555</a></p>
                    <p> <a href="tel:491625800333">+49 162 5800333</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-info">
                    <i class='bx bxs-location-plus'></i>
                    <h4 translate>contact_page_adres</h4>
                    <p>Heuweg 90, 67065 Ludwigshafen am Rhein</p>
                    <p>Germany</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-info">
                    <i class='bx bxs-envelope'></i>
                    <h4 translate>contact_page_mail</h4>
                    <p><a href="mailto:info@altuntureuropa.com">info@altuntureuropa.com</a></p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="contact-form-area pb-100">
    <div class="container">
        <div class="section-title">
            <span translate>contact_page_form1</span>
            <h2 translate>contact_page_form1</h2>
        </div>

        <div class="contact-form">

            <div *ngIf="returnText && returnStatus == 'true'" class="alert alert-success text-center">{{ returnText }}</div>
            <div *ngIf="returnText && returnStatus == 'false'" class="alert alert-danger text-center">{{ returnText }} {{ returnError }}</div>

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="name" placeholder="{{ 'contact_page_form_ad' | translate }}" required="required">
                            <div *ngIf="f.name.touched && f.name.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.name.errors && f.name.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="email" class="form-control" formControlName="email" required placeholder="{{ 'contact_page_form_email' | translate }}">
                            <div *ngIf="f.email.touched && f.email.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.email.errors && f.email.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="msgSubject" required placeholder="{{ 'contact_page_form_konu' | translate }}">
                            <div *ngIf="f.msgSubject.touched && f.msgSubject.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.msgSubject.errors && f.msgSubject.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="phoneNumber" required placeholder="{{ 'contact_page_form_tel' | translate }}">
                            <div *ngIf="f.phoneNumber.touched && f.phoneNumber.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.phoneNumber.errors && f.phoneNumber.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea class="form-control" cols="30" rows="6" formControlName="message" required placeholder="{{ 'contact_page_form_message' | translate }}"></textarea>
                            <div *ngIf="f.message.touched && f.message.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                <div *ngIf="f.message.errors && f.message.errors.required" translate>contact_page_form_zorunlu</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn-one" translate>contact_page_form_btn</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div> 


<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3 translate="bulten_title"></h3>
                        <p translate="bulten_desc"></p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="{{ 'bulten_input' | translate }}" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary" translate="bulten_btn"></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>