<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 translate="404_page_title"></h2>
                    <ul>
                        <li><a routerLink="/" translate="home"></a></li>
                        <li translate="404_page_title"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error">
                    <img class="error-image" src="assets/img/error.png" alt="image">
                    <h2 translate="404_page_title"></h2>
                    <div class="error-btn">
                        <a routerLink="/" translate="404_page_anasayfa"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3 translate="bulten_title"></h3>
                        <p translate="bulten_desc"></p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="{{ 'bulten_input' | translate }}" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary" translate="bulten_btn"></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>