<div class="hero-slider owl-carousel owl-theme">

    <!--<div class="hero-slider-item bg-out" style="background-image: url(assets/img/slider/altuntur.jpg);">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span translate>slider.slider1.since</span>
                        <h1 translate>slider.slider1.title</h1>
                        <p translate>slider.slider1.desc</p>
                        <a routerLink="/about" class="default-btn-one mr-3" translate>slider.slider1.btn1_text</a>
                        <a routerLink="/contact" class="default-btn-two" translate>slider.slider1.btn2_text</a>
                    </div> 
                </div>
            </div>
        </div>
    </div>-->

    <div class="hero-slider-item" style="background-image: url(assets/img/slider/1.jpg);"></div>

    <div class="hero-slider-item" style="background-image: url(assets/img/slider/2.jpg);"></div>

</div>


<div class="container">
    <div class="contact-area mb-85">
        <div class="contact-content">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-truck justify-content-center'></i>
                        <h4 class="mb-0" translate>index_online_cargo</h4>
                        <p translate>index_online_cargo_text</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-calculator'></i>
                        <h4 translate>index_cargo_calc</h4>
                        <p translate>index_cargo_calc_text</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bx-file'></i>
                        <h4 translate>index_fatura_bilgileri</h4>
                        <p><a routerLink="/billing"><span translate>index_fatura_bilgileri_text</span></a></p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="contact-card">
                        <i class='bx bxs-map'></i>
                        <h4 translate>index_subeler</h4>
                        <p><a routerLink="/branches"><span translate>index_subeler_text</span></a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="about-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="about-contant">
                    <div class="section-title">
                        <span translate>index_about_us_title</span>
                        <h2 translate>index_about_us_title_text</h2>
                    </div>
                    <div class="about-text">
                        <p translate>about_page_desc1</p>
                        <p translate>about_page_desc2</p>
                        <a routerLink="/about" class="default-btn-one btn-bs"><span translate>genel_read_more</span> <i class="bx bxs-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about_us.jpg" alt="about-image">
                </div>
            </div>

            <div class="col-lg-3 col-md-12">
                <div class="about-tabs">
                    <div class="tab-contant">
                        <h2 class="title" translate>index_our_core_title</h2>
                        <nav>
                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                <a class="nav-link active" id="nav-vision-tab" data-bs-toggle="tab" href="#nav-vision" role="tab" aria-controls="nav-vision" aria-selected="true" translate>index_our_core_tab_vision</a>
                                <a class="nav-link nav-link-two" id="nav-mission-tab" data-bs-toggle="tab" href="#nav-mission" role="tab" aria-controls="nav-mission" aria-selected="false" translate>index_our_core_tab_mission</a>
                            </div>
                        </nav>

                        <div class="tab-content" id="nav-tabContent">
                            <div class="tab-pane fade show active" id="nav-vision" role="tabpanel">
                                <div class="vision">
                                    <ul>
                                        <li><i class="bx bx-check"></i> <span translate="index_our_core_tab_vision_desc"></span></li>
                                    </ul>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="nav-mission" role="tabpanel">
                                <div class="vision">
                                    <ul>
                                        <li><i class='bx bx-check'></i> <span translate="index_our_core_tab_mission_desc"></span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <div class="d-flex justify-content-center">
                    <video controls autoplay [muted]="'muted'" width="1146">
                        <source src="assets/img/video2.mp4" type="video/mp4">
                    </video>
                </div>

            </div>
        </div>
    </div>
</div>


<div class="container">
    <div class="counter-area">
        <div class="counter-contant">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class="bx bx-world"></i>
                        <h3><span class="odometer" data-count="49">00</span></h3>
                        <p translate="sayac_title1"></p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class='bx bxs-home'></i>
                        <h3><span class="odometer" data-count="2300">00</span></h3>
                        <p translate="sayac_title2"></p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class='bx bxs-truck'></i>
                        <h3><span class="odometer" data-count="50">00</span></h3>
                        <p translate="sayac_title3"></p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="counter-card">
                        <i class='bx bxs-group'></i>
                        <h3><span class="odometer" data-count="200">00</span></h3>
                        <p translate="sayac_title4"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title mt-5">
            <span translate>index_our_service</span>
            <h2 translate>index_our_service_sub</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bxs-ship'></i>
                    <h3 translate="index_our_service1_title"></h3>
                    <p translate="index_our_service1_desc"></p>
                    <a href="javascript:void(0);" class="default-btn-two" translate>genel_read_more2</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bx-store'></i>
                    <h3 translate="index_our_service2_title"></h3>
                    <p translate="index_our_service2_desc"></p>
                    <a href="javascript:void(0);" class="default-btn-two" translate>genel_read_more2</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bxs-truck'></i>
                    <h3 translate="index_our_service3_title"></h3>
                    <p translate="index_our_service3_desc"></p>
                    <a href="javascript:void(0);" class="default-btn-two" translate>genel_read_more2</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="service-card">
                    <i class='bx bx-transfer'></i>
                    <h3 translate="index_our_service4_title"></h3>
                    <p translate="index_our_service4_desc"></p>
                    <a href="javascript:void(0);" class="default-btn-two" translate>genel_read_more2</a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="personal-data-area ptb-100"  style="background: #f5f5f5;">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="safely-image">
                    <img src="assets/img/safely/safely1.jpg" alt="image">

                    <div class="caption">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <h3 translate="index_teklif_title"></h3>
                                <p translate="index_teklif_desc"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="personal-data-form">
                    <h2 translate="index_teklif_form"></h2>
                    
                    <div *ngIf="returnText && returnStatus == 'true'" class="alert alert-success text-center">{{ returnText }}</div>
                    <div *ngIf="returnText && returnStatus == 'false'" class="alert alert-danger text-center">{{ returnText }} {{ returnError }}</div>

                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="name" placeholder="{{ 'home_page_form_ad' | translate }}" required="required">
                                    <div *ngIf="f.name.touched && f.name.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                        <div *ngIf="f.name.errors && f.name.errors.required" translate>contact_page_form_zorunlu</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="email" class="form-control" formControlName="email" placeholder="{{ 'home_page_form_mail' | translate }}" required="required">
                                    <div *ngIf="f.email.touched && f.email.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                        <div *ngIf="f.email.errors && f.email.errors.required" translate>contact_page_form_zorunlu</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="phoneNumber" required placeholder="{{ 'home_page_form_tel' | translate }}">
                                    <div *ngIf="f.phoneNumber.touched && f.phoneNumber.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                        <div *ngIf="f.phoneNumber.errors && f.phoneNumber.errors.required" translate>contact_page_form_zorunlu</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 translate="index_teklif_form_detay"></h2>
                        
                        <div class="row">

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="malCinsi" required placeholder="{{ 'home_page_form_mal' | translate }}">
                                    <div *ngIf="f.malCinsi.touched && f.malCinsi.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                        <div *ngIf="f.malCinsi.errors && f.malCinsi.errors.required" translate>contact_page_form_zorunlu</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="m3" required placeholder="{{ 'home_page_form_m3' | translate }}">
                                    <div *ngIf="f.m3.touched && f.m3.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                        <div *ngIf="f.m3.errors && f.m3.errors.required" translate>contact_page_form_zorunlu</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="kg" required placeholder="{{ 'home_page_form_kg' | translate }}">
                                    <div *ngIf="f.kg.touched && f.kg.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                        <div *ngIf="f.kg.errors && f.kg.errors.required" translate>contact_page_form_zorunlu</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="yukleme" required placeholder="{{ 'home_page_form_yukleme' | translate }}">
                                    <div *ngIf="f.yukleme.touched && f.yukleme.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                        <div *ngIf="f.yukleme.errors && f.yukleme.errors.required" translate>contact_page_form_zorunlu</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="bosaltma" required placeholder="{{ 'home_page_form_bosaltma' | translate }}">
                                    <div *ngIf="f.bosaltma.touched && f.bosaltma.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                        <div *ngIf="f.bosaltma.errors && f.bosaltma.errors.required" translate>contact_page_form_zorunlu</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="aciklama" required placeholder="{{ 'home_page_form_aciklama' | translate }}">
                                    <div *ngIf="f.aciklama.touched && f.aciklama.invalid" style="color:red;padding: 5px 0 0 5px;font-size: 16px;font-weight: 600;">
                                        <div *ngIf="f.aciklama.errors && f.aciklama.errors.required" translate>contact_page_form_zorunlu</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <button type="submit" class="btn btn-primary" translate="index_teklif_form_btn"></button>
                    </form>
                </div>
            </div>
        </div>

        <div class="shape-three"><img src="assets/img/shape/shape6.png" alt="Shape"></div>
    </div>
</div>


<div class="choose-area ptb-100">
    <div class="shape-one"><img src="assets/img/shape/shape5.png" alt="Shape"></div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bx-world'></i>
                        <h3 translate="index_neden_title1"></h3>
                        <p translate="index_neden_desc1"></p>
                    </div>
                    
                </div>

                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bxs-paper-plane'></i>
                        <h3 translate="index_neden_title2"></h3>
                        <p style="height: 46px;" translate="index_neden_desc2"></p>
                    </div>
                </div>

                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bxs-truck'></i>
                        <h3 translate="index_neden_title3"></h3>
                        <p translate="index_neden_desc3"></p>
                    </div>
                </div>

                <div class="choose-contant">
                    <div class="choose-card">
                        <i class='bx bx-support'></i>
                        <h3 translate="index_neden_title4"></h3>
                        <p translate="index_neden_desc4"></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="choose-text">
                    <div class="section-title">
                        <span translate="index_neden_biz1"></span>
                        <h2 translate="index_neden_biz2"></h2>
                    </div>
                    <p translate="index_neden_biz_desc1"></p>
                    <p translate="index_neden_biz_desc2"></p>
                    <a routerLink="/contact" class="default-btn-one" translate="contact"></a>
                    <div class="shape-image"><img src="assets/img/shape/shape2.png" alt="icon"></div>
                </div>
            </div>
        </div>
    </div>
</div>
 
