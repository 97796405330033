<div class="page-banner" style="background-image: url(assets/img/contact-image.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 translate>billing</h2>
                    <ul>
                        <li><a routerLink="/" translate>home</a></li>
                        <li translate>billing</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-text-area ptb-100">
    <div class="container">
        <div class="row align-items-center text-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-safe-text">
                    <h2 translate>billing</h2>

                    <p>ALTUNTUR EUROPA GMBH</p>
                    <p>Geschäftsführer MURSEL ALKhayat</p>
                    <p>HEUWEG 90</p>
                    <p>67065 Ludwigshafen </p>
                    <p>Germany</p>
                    <br><br>
                    <p>Steuer Nummer : 27/665/00768</p>
                    <p>Ust-idNr: DE328108149</p>
                    <p>Bank VR Bank Rhein-Necker eG</p>
                    <p>SWIFT/BIC : GENODE61MA2</p>
                    <p>IBAN : DE50 6709 0000 0095 0846 05</p>
                    <p>Kontoinhaber Altuntur Europa GmbH</p>
                    <p>MURSEL ALKhayat</p>
                </div>
            </div>
        </div>
    </div>
</div>
