<div class="page-banner" style="background-image: url(assets/img/services-image.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>Hava Kargo Taşımacılığı</h2>
                    <ul>
                        <li><a routerLink="/services">Hizmetler</a></li>
                        <li>Hava Kargo Taşımacılığı</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="single-services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="service-details-text"> 
                    <div class="service-image">
                        <img src="assets/img/services/services3.jpg" alt="image">
                    </div>
                    <h2>Hava Kargo Taşımacılığı</h2>

                    <p>Altuntur olarak, lider hava yolu şirketleri ile kurduğumuz güçlü ilişkiler ve Uluslararası Hava Taşımacılığı Organizasyonu (IATA) lisansımızla tüm gönderilerinizi dünyanın dört bir yanına tam zamanında ulaştırıyoruz. Uluslararası acente ağlarımız ile tüm dünyada global hava kargo taşımacılığında anahtar teslim hizmetler ile karşınızdayız.</p>
                    <p>Hava kargo taşımacılığında çeyrek asırlık global forwarding deneyimi</p>
                    <p>Ticaret yapılan her ülkeye hızlı ve kolay erişim</p>
                    <p>Küresel itibarımız sayesinde güvenilir iş ortakları ve temsilciler ile bağlantı</p>
                    
                    <h4 class="mt-5">Konsolidasyon Hizmetleri</h4>
                    <p>Altuntur olarak, ürünleriniz neredeyse ihtiyaç duyacağı her türlü ihracat ve ithalat konsolidasyon işlemlerini sizin adınıza yürütüyoruz. Farklı tedarikçilerden ürünlerinizi teslim alıyor, Dünyadaki iş ortaklarının konsolidasyon merkezlerinde yüklemeye hazır durumda bekletiyoruz. Sevkiyat sırasında ürünlerinizin yüklenmesini planlıyor, aynı zamanda sizin adınıza gerekli tüm belgeleri de hazırlıyoruz. Size sadece bu kolaylığın keyfini sürmek kalıyor!</p>
                
                    <h4 class="mt-5">PO (Purchase Order) Yönetimi</h4>
                    <p>Farklı tedarikçilerden temin ettiğiniz ürünleri belirlediğiniz varış noktalarına güvenli bir şekilde ulaştırabilmeniz için geliştirdiğimiz Purchase Order Yönetimi Sistemimiz sayesinde gönderilerinizi üretim aşamasından itibaren sipariş numarası üzerinden takip edebilirsiniz. Bu sayede ürünlerinizin doğru konteynerlere yerleştirildiğinden ve doğru rotada seyahat ettiğinden emin olabilir, tedarik zincirindeki satış ve satın alma işlemlerini yönetebilirsiniz. Purchase Order Yönetimi Sistemimiz ile tüm gönderileriniz 7/24 parmaklarınızın ucunda.</p>
                
                    <h4 class="mt-5">Ek Hizmetler</h4>
                    <p>Yükleme Ülkesinde Gümrükleme Hizmetleri, Varış Ülkesinde Gümrükleme Hizmetleri, Yükleme Ülkesinde Kargo Toplama, Kalite Kontrol Hizmetleri, Ürün Belgelendirme ve Test, Katma Değerli Hizmetler, Gümrüklü Depolama, Kargo Sigortası</p>
                </div> 
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="service-sidebar">
                    <h3 class="title">Diğer Hizmetlerimiz</h3>

                    <ul>
                        <li><a href="/services-road"><i class='bx bxs-truck'></i> Karayolu Taşımacılığı</a></li>
                        <li><a href="/services-ocean"><i class='bx bxs-ship'></i> Deniz Yolu Taşımacılığı</a></li>
                        <li><a href="/services-air"><i class='bx bxs-plane-take-off'></i> Hava Kargo</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Haber Bültenine Abone Ol</h3>
                        <p>Güncel kampanyalardan haberdar olmak için abone olabilirsiniz.</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Email Adresiniz" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Abone Ol</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>