<div class="page-banner" style="background-image: url(assets/img/services-image.jpg);">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2 translate="hizmetler"></h2>
                    <ul>
                        <li><a routerLink="/" translate="home"></a></li>
                        <li translate="hizmetler"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="our-services-area ptb-50">
    <div class="container">
        <div class="section-title">
            <h5 translate="services_page_title"></h5>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services1.jpg" alt="image">
                    <div class="service-caption">
                        <h3 translate="services_page_title1"></h3>
                        <p translate="services_page_desc1"></p>
                        <a routerLink="/services-road" class="default-btn-two" translate="services_page_btn"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services2.jpg" alt="image">
                    <div class="service-caption">
                        <h3 translate="services_page_title2"></h3>
                        <p translate="services_page_desc2"></p>
                        <a routerLink="/services-ocean" class="default-btn-two" translate="services_page_btn"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card-two">
                    <img src="assets/img/services/services3.jpg" alt="image">
                    <div class="service-caption">
                        <h3 translate="services_page_title3"></h3>
                        <p translate="services_page_desc3"></p>
                        <a routerLink="/services-air" class="default-btn-two" translate="services_page_btn"></a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="best-logistic-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="logistic-text">
                    <h2><span translate="services_page_about_title"></span></h2>
                    <p translate="services_page_about_desc1"></p>
                    <p translate="services_page_about_desc2"></p>
                    <p translate="services_page_about_desc3"></p>
                    <p translate="services_page_about_desc4"></p>
                </div>
                <a routerLink="/about" class="default-btn-one" translate="services_page_about_detay"></a>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="logistic-image">
                    <img src="assets/img/about_us.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>


<div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3 translate="bulten_title"></h3>
                        <p translate="bulten_desc"></p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="{{ 'bulten_input' | translate }}" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary" translate="bulten_btn"></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>