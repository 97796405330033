import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.scss']
})

export class AgenciesComponent implements OnInit {

  

  private _jsonURL = 'https://api.altuntureuropa.com/api/App/GetAgentList';
  response: any;

  filterTerm: string;
  title: any;
  dataList: any = [ ];
  location: any;

  constructor(private http: HttpClient,private translate: TranslateConfigService, private sanitizer:DomSanitizer) {
    
    /*this.http.get(this._jsonURL).subscribe(data => {
      this.response = data["data"];
    });*/

    this.http.get(this._jsonURL).subscribe((data: any)=> {    
      data["data"].forEach(element => { 
         
        this.location = this.transform(element.location);         
        this.dataList.push({
          company: element.company,
          city: element.city,
          code: element.code,
          title: element.title,
          authorized: element.authorized,
          phone1: element.phone1,
          mail: element.mail,
          address1: element.address1,
          address2: element.address2,
          country: element.country,
          location: this.location
        });
      });
      this.response = this.dataList;
  });
        
  }

  ngOnInit(): void {}

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
}
