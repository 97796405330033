import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AgenciesComponent } from "./agencies.component";
import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";


export function rootLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/languages/', '.json');
}
@NgModule({
  imports: [CommonModule,FormsModule,Ng2SearchPipeModule,TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: rootLoaderFactory,
      deps: [HttpClient],
    },
  }),],
  declarations: [AgenciesComponent]
})
export class AgenciesModule {}